import React from "react"
import "react-multi-carousel/lib/styles.css"
import { Card } from "react-bootstrap"
import Carousel from "react-multi-carousel"

import teamData from "../assets/data/teams.json"

export default function experts() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <div className="mt-5 pt-5 mb-5 px-0 px-md-5 px-lg-5 px-xl-5">
      <h1 className="heading mb-5">OUR EXPERTS</h1>
      <Carousel
        responsive={responsive}
        // arrows={true}
        infinite={true}
        autoPlay={true}
      >
        {teamData.map(data => {
          if (data.attributes.keys === "experts") {
            return (
              <div className="m-3">
                <Card className="speaker-cards">
                  <Card.Img
                    variant="top"
                    // style={{ height: "65%", width: "100%" }}
                    src={
                      data?.attributes?.thumbnail?.data?.[0]?.attributes?.url
                    }
                  />
                  <Card.Body>
                    <div className="row">
                      <div className="col-9">
                        <div className="card-heading fw-bold">
                          {data.attributes.name}
                        </div>
                      </div>
                      {data.attributes.linkedin && (
                        <div className="col-3 text-end">
                          <a target="_blank" href={data.attributes.linkedin}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </div>
                      )}
                    </div>
                    <p className="designation-text pt-2">
                      {data.attributes.designation}
                    </p>
                  </Card.Body>
                </Card>
              </div>
            )
          }
        })}
      </Carousel>
    </div>
  )
}
