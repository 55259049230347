import React, { useState, useEffect } from "react"
import one from "../images/newDesign/offer/1.jpg"
import two from "../images/newDesign/offer/2.jpg"
import three from "../images/newDesign/offer/3.jpg"
import four from "../images/newDesign/offer/4.jpg"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Advisor from "./advisors"
import Experts from "./experts"
import Events from "./event"
import { Link } from "gatsby"
import BannerData from "../assets/data/banners.json"
import recentActivites from "../assets/data/recentActivites.json"
import ReactMarkdown from "react-markdown"
import { graphql, useStaticQuery } from "gatsby"
import InstagramFeed from "./social_media_repository/instagramFeed"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import popup from "../images/newDesign/socialMedia/Pop_up_window_Fundamentals_of_ESG.jpg"
export default function HeroNew() {
  const data = useStaticQuery(graphql`
    query {
      allInstagramContent(limit: 4, filter: { media_type: { eq: "IMAGE" } }) {
        edges {
          node {
            media_id
            media_type
            media_url
            permalink
            username
            caption
          }
        }
      }
    }
  `)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const modalTimeout = setTimeout(() => {
      handleShowModal()
      localStorage.setItem("modalShown", "true")
    }, 5000)

    return () => {
      clearTimeout(modalTimeout)
    }
  }, [])

  return (
    <>
      <setion>
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a href="http://iesgn.skillstrainer.in/course-details/226">
              <img src={popup} className="img-fluid" target="_blank" />
            </a>
          </Modal.Body>
        </Modal>
        <div className="container-custom">
          <div className="px-2 mx-2">
            <Carousel
              className="carousal-custom"
              responsive={responsive}
              itemClass="carousel-item-padding-40-px"
              draggable={false}
              showDots={false}
              infinite={true}
              autoPlay={true}
              arrows={false}
              autoPlaySpeed={3000}
            >
              {/* <div>
                <div>
                  <Link to="/delhi-programs">
                    <p className="pt-2"></p>
                    <div className="marquee">
                      <div>
                      Registrations are open now for the CSC-IESGN SDG Conclave 2024.
                      </div>
                      Click to register and be part of shaping a better tomorrow!
                    </div>
                  </Link>
                </div>
                <a href="">
                  <div className="marquee"></div>
                </a>
              </div> */}
              <div>
                <a href="https://www.indianesgnetwork.com/courses/foundamentals-of-esg-course">
                  <p className="pt-2"></p>
                  <div className="marquee">
                    <div>
                      Fundamental of ESG course is live and open for enrollment.
                      Join us now
                    </div>
                    Click to Join the course
                  </div>
                </a>
              </div>
            </Carousel>
          </div>

          <Carousel
            className="carousal-custom"
            responsive={responsive}
            itemClass="carousel-item-padding-40-px"
            draggable={false}
            showDots={true}
            infinite={true}
            autoPlay={true}
            arrows={true}
            autoPlaySpeed={8000}
          >
            {BannerData.map(data => {
              console.log("Banner", BannerData);
              if (data.attributes.banner_type !== "only_delhi_event_banner"){
                return (
                  <div>
                    <div className="banner-btn-parent">
                      <Link className="nav-link" to={data.attributes.button_url}>
                        {data.attributes.button_lable && (
                          <button className="btn banner-btn -green mt-4">
                            {data.attributes.button_lable}
                          </button>
                        )}
                      </Link>
                    </div>
                    <div>
                      <img src={data.attributes.banner.data[0].attributes.url} />
                    </div>
                  </div>
                )
              }
            })}
          </Carousel>

          {/* ===============About Us============ */}
          {/* ORIENTATION PROGRAM */}
          <div className="mt-5 pt-5 px-0 px-md-5 px-lg-5 px-xl-5">
            <Carousel
              className="carousal-custom"
              responsive={responsive}
              itemClass="carousel-item-padding-40-px"
              draggable={false}
              showDots={true}
              infinite={true}
              autoPlay={true}
              arrows={true}
              autoPlaySpeed={8000}
            >
              {recentActivites.map(data => {
                return (
                  <div>
                    <div className="row align-items-center">
                      <div className="col-md-6 mb-4">
                        <h1 className="common-heading mb-3">
                          {data.attributes.heading}
                        </h1>
                        <h4>{data?.attributes?.subheading}</h4>
                        <div align="justify" className="comman-para">
                          <ReactMarkdown>
                            {data.attributes.paragraph}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <iframe
                          width="98%"
                          height="315"
                          src={data.attributes.url}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>

          {/* =================what we offer=========  */}
          <div className="mt-5 pt-5 px-0 px-md-5 px-lg-5 px-xl-5">
            <h1 className="common-heading mb-5">WHAT WE OFFER</h1>
            <div className="row">
              {/* ==========Join the Network======= */}
              <div className="col-12 col-md-3 col-lg-3  mb-4 border text-center ">
                <img src={one} alt={``} className="img-fluid offer-card" />
              </div>
              <div className="col-12 col-md-9 col-md-9 p-4 mb-4 text-start common-card">
                <h4 className="common-second-heading fw-bold">ESG Network</h4>
                <div align="justify" className="comman-para">
                  We welcome you to join our Network and contribute as ESG
                  speakers, trainers, collaborators, knowledge partners and
                  implementation partners if you are keen to support the vision
                  of building an Environment conscious community.
                </div>
                <div className="text-white mt-5">
                  <Link
                    className="common-second-heading"
                    target="_blank"
                    to="/join-our-community-form"
                  >
                    JOIN THE NETWORK
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9 col-md-9 p-4 mb-4 text-start common-card order-last order-sm-last order-md-first order-lg-first order-xxl-first">
                <h4 className="common-second-heading fw-bold">Events</h4>
                <div align="justify" className="comman-para">
                  Build Awareness of ESG and its concepts amongst various
                  stakeholders through events, retreats, workshops, quizzes,
                  articles and blogs for ease of implementation and
                  understanding of frameworks etc.
                </div>
                <div className="common-second-heading mt-5">LEARN MORE</div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 justify-content-center mb-4 border text-center order-first order-sm-first order-md-last order-lg-last order-xxl-last">
                <img src={two} alt={``} className="img-fluid offer-card" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3 col-lg-3  mb-4 border text-center">
                <img src={three} alt={``} className="img-fluid offer-card" />
              </div>
              <div className="col-12 col-md-9 col-md-9 p-4 mb-4 text-start common-card">
                <h4 className="common-second-heading fw-bold">
                  Training Programs
                </h4>
                <div align="justify" className="comman-para">
                  Structured Online/ Oﬄine ESG Trainings for Management and
                  Employees on various aspects of ESG - BSRSR Reporting
                  Framework, Social Audit and Social Impact Assessment etc.
                </div>

                <Link to="/training-programs">
                  <div className="common-second-heading mt-5">LEARN MORE</div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9 col-md-9 p-4 mb-4 text-start common-card order-last order-sm-last order-md-first order-lg-first order-xxl-first">
                <h4 className="common-second-heading fw-bold">
                  Knowledge Repository
                </h4>
                <div align="justify" className="comman-para">
                  The Indian ESG Network will be sharing various ESG related
                  topics, latest regulatory changes and best practises as
                  knowledge repository.
                </div>

                <Link to="/knowledge-repository">
                  <div className="common-second-heading mt-5">LEARN MORE</div>
                </Link>
              </div>

              <div className="col-12 col-md-3 col-lg-3 justify-content-center mb-4 border text-center order-first order-sm-first order-md-last order-lg-last order-xxl-last">
                <img src={four} alt={``} className="img-fluid offer-card" />
              </div>
            </div>
          </div>
          <Events />
          <Advisor />
          <Experts />

          <div>
            <div className="px-0 px-md-5 px-lg-5 px-xl-5 mt-5 pt-5 mb-5 ">
              <h1 className="common-heading mb-5">SOCIAL MEDIA POSTS</h1>

              <div className="row justify-content-center">
                <InstagramFeed data={data} />
              </div>
            </div>
          </div>
        </div>
      </setion>
    </>
  )
}
