import React from "react"

const InstagramFeed = ({ data }) => {
  console.log("data", data);
  if (!data || !data.allInstagramContent) {
    return <div>No Instagram content available.</div>;
  }
  const posts = data.allInstagramContent.edges
  return (
    <div>
      <div className="row">
        {posts.map(({ node }) => (
          <div className="col-md-3 col-with-hover" key={node.media_id}>
            <a href={node.permalink} target="_blank">
              <div className="image-container">
                <img src={node.media_url} alt={node.caption} className="img-overlay"/>
              </div>
              <p className="text-container">{node.caption}</p>
              <div className="link-overlay">
                Read More
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
export default InstagramFeed